import React, { useMemo } from "react";
import { Box, Button } from "@chakra-ui/react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { useButtonProps } from "hooks";
import type { IField } from "screens/common/components";
import { DynamicForm } from "screens/common/components";

export interface CreateTickerForm {
  symbol: string;
  name: string;
  exchange: string;
}

interface IProps {
  onSubmit: (values: CreateTickerForm) => void;
  isLoading?: boolean;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const CreateTicker = ({ onSubmit, isLoading }: IProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");

  const fields: IField<CreateTickerForm>[] = useMemo(
    () => [
      {
        type: "text",
        entity: "symbol",
        label: "Symbol",
        isRequired: true,
        defaultValue: "",
      },
      {
        type: "text-with-suggestions",
        entity: "exchange",
        label: "Exchange",
        isRequired: true,
        defaultValue: "",
        suggestions: ["NASDAQ", "NYSE", "TSX"],
      },
      {
        type: "text",
        entity: "name",
        label: "Name",
        isRequired: true,
        defaultValue: "",
      },
    ],
    []
  );

  const defaultValues: CreateTickerForm = useMemo(() => {
    return {
      symbol: "",
      exchange: "",
      name: "",
    };
  }, []);

  return (
    <Box>
      <DynamicForm<CreateTickerForm>
        formId="create-ticker-form"
        defaultValues={defaultValues}
        fields={fields}
        onSubmit={(values) => onSubmit(values)}
        title="Create ticker"
        isDisabled={isLoading}
      />

      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button isLoading={isLoading} {...commonButtonProps} type="submit" form="create-ticker-form">
          Save Ticker
        </Button>
      </Box>
    </Box>
  );
};

const CreateTickerPanel = (props: IPanelProps) => {
  const { onClose, isOpen, ...rest } = props;

  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={"Create ticker"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && <CreateTicker {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};

export { CreateTickerPanel };
