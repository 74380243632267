import { Stack, Text, useColorModeValue, Center, Box, Skeleton } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { usePortfolioCollections, useSkeletonProps } from "hooks";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useWizard } from "react-use-wizard";
import { ONBOARDING_MODEL_HEIGHT } from "./OnboardingModal";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";

export const OnboardingNewProjectStep = () => {
  const textColor = useColorModeValue("gray.500", "gray.400");
  const portfolioCollections = usePortfolioCollections();
  const [portfolioId, setPortfolioId] = React.useState<string | null>(null);
  const { isOnboardingModalOpen } = useConversationContext();
  const { nextStep } = useWizard();
  const skeletonStyle = useSkeletonProps();

  useEffect(() => {
    if (portfolioCollections && portfolioCollections.length > 0) {
      setPortfolioId(portfolioCollections[0].id);
    }
  }, [portfolioCollections]);

  const handleOnSubmitDueDiligence = () => {
    if (isOnboardingModalOpen) {
      nextStep();
    }
  };

  return (
    <Stack justifyContent="space-between" spacing="0" height={ONBOARDING_MODEL_HEIGHT}>
      <Stack
        color="white"
        bgColor="#4799d4"
        direction={"row"}
        fontSize="md"
        textAlign="center"
        width="100%"
        height={"3rem"}
        justifyContent={"space-between"}
        px="1rem">
        <Center>
          <Text fontWeight={"semibold"} fontSize="lg">
            STEP 2: Enter a Ticker
          </Text>
        </Center>
      </Stack>
      <Stack spacing="3rem" px="3.5rem" pt="2rem" height="100%" justifyContent={"space-between"}>
        <Stack spacing="3rem">
          <Text fontSize="md" width="100%" color={textColor} textAlign="center">
            Your automated due diligence starts by simply entering a ticker of a company on the NYSE, NASDAQ, and TSX exchanges. Once Charli
            has completed the analysis you will receive a detailed report in your inbox.
          </Text>
          <Text color="charli.primaryBlue" fontWeight={"semibold"} textAlign="center">
            Enter a ticker that is important to your portfolio. You will get the opportunity to run 2 more tickers after this
          </Text>
          {portfolioId ? (
            <Box pb="7.5rem">
              <ConversationDialogInput
                inputId={"view-input"}
                afterSubmit={handleOnSubmitDueDiligence}
                initialText={"Enter the ticker of a company"}
                minWidth="25rem"
              />
            </Box>
          ) : (
            <Box pb="7.5rem">
              <Center>
                <Text fontSize="md" color={textColor}>
                  I'm finishing up creating your portfolio. Please wait a moment...
                </Text>
              </Center>
              <Skeleton {...skeletonStyle} borderRadius={"full"} width="100%" height="3rem"></Skeleton>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
