import { Stack, Text, Image, useColorModeValue, SimpleGrid, Center, Tooltip, Flex } from "@chakra-ui/react";
import type { FC } from "react";
import React, { useMemo, useState, useEffect } from "react";
import { useTileProps } from "hooks";

export type TileSelectorProps = {
  label: string;
  entityName: string;
  tooltip?: string;
  value: string;
  image: string;
  imageDimensions?: { width: string; height: string };
  disabled?: boolean;
  default?: boolean;
};

interface Props {
  title?: string;
  layout?: "scroll" | "grid";
  tiles: TileSelectorProps[];
  onSelectedTile?: (tile: TileSelectorProps) => void;
}

export const TileSelector: FC<Props> = ({ tiles, title, layout, onSelectedTile }) => {
  const commonTileProps = useTileProps();
  const defaultTileIndex = tiles.findIndex((tile) => tile.default);
  const [isSelectedTile, setIsSelectedTile] = useState(defaultTileIndex || 0);
  const buttonColor = useColorModeValue("gray.700", "gray.400");

  useEffect(() => {
    setIsSelectedTile(defaultTileIndex !== -1 ? defaultTileIndex : 0);
  }, [defaultTileIndex, tiles]);

  const tilesStack = useMemo(() => {
    return tiles.map((tile, index) => {
      return (
        <Tooltip key={index} label={tile.tooltip} aria-label={tile.tooltip} textAlign="center" maxWidth={"11rem"}>
          <Stack
            key={`slide-${index}`}
            {...commonTileProps}
            minWidth={layout === "scroll" ? "9.5rem" : "auto"}
            cursor={tile.disabled ? "not-allowed" : "pointer"}
            opacity={tile.disabled ? 0.5 : 1}
            py={"2rem"}
            onClick={() => {
              if (tile.disabled) return;
              onSelectedTile && onSelectedTile(tile);
              setIsSelectedTile(index);
            }}
            bgColor={isSelectedTile === index ? "gray.100" : "white"}>
            <Center>
              <Image
                borderRadius={"md"}
                height={tile.imageDimensions?.height ? tile.imageDimensions.height : "5.5rem"}
                width={tile.imageDimensions?.width ? tile.imageDimensions.width : "100%"}
                src={tile.image}
                alt={tile.label}
              />
            </Center>
            <Text textAlign={"center"} fontSize="sm" fontWeight="normal" width="100%" px=".5rem">
              {tile.label}
            </Text>
          </Stack>
        </Tooltip>
      );
    });
  }, [commonTileProps, isSelectedTile, layout, onSelectedTile, tiles]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesCount = tilesStack.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };
  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };

  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 75}%`,
  };
  const shadowColor = useColorModeValue("#00000080", "#000000");

  const arrowStyles = {
    cursor: "pointer",
    pos: "absolute",
    top: "50%",
    w: "auto",
    mt: "-22px",
    mx: "-12px",
    p: "1px 12px",
    bg: "white",
    color: "gray.700",
    fontWeight: "bold",
    fontSize: "22px",
    transition: "0.6s ease",
    borderRadius: "10px",
    userSelect: "none",
    boxShadow: `0 4px 46px ${shadowColor}`,
    _hover: {
      bg: "gray.100",
    },
  } as const;

  return (
    <Stack>
      {title && (
        <Text fontWeight="semibold" isTruncated fontSize="sm" color={buttonColor}>
          {title}
        </Text>
      )}
      {layout === "scroll" ? (
        <Flex w="full" alignItems="center" justifyContent="center">
          <Flex w="full" overflow="hidden" pos="relative">
            <Stack direction={"row"} spacing="1rem" h="100%" w="full" {...carouselStyle}>
              {tilesStack}
            </Stack>
            {currentSlide === 1 && (
              <Text {...arrowStyles} left="0" onMouseEnter={prevSlide}>
                &#10094;
              </Text>
            )}
            {currentSlide === 0 && (
              <Text {...arrowStyles} right="0" onMouseEnter={nextSlide}>
                &#10095;
              </Text>
            )}
          </Flex>
        </Flex>
      ) : (
        <SimpleGrid columns={3} spacing="1rem">
          {tilesStack}
        </SimpleGrid>
      )}
    </Stack>
  );
};
