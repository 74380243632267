import {
  useColorModeValue,
  InputGroup,
  InputLeftAddon,
  IconButton,
  InputRightElement,
  Box,
  Stack,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import type { SuggestedQuestion, SuggestedQuestions } from "api/suggestions";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import type { AutoCompleteRefMethods } from "@choc-ui/chakra-autocomplete";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import HelpPopover from "screens/landing/components/popoverComponent/HelpPopover";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { v4 as uuid } from "uuid";
import { InputBarAttachmentButton } from "screens/conversation/components/InputBar/InputBarAttachmentButton";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";

export interface AutocompleteInputProps {
  initialText?: string;
  suggestedQuestions?: SuggestedQuestions;
  isInputDisabled?: boolean;
  isSubmitDisabled?: boolean;
  onSelectOption: (value: SuggestedQuestion) => void;
  value: string;
  onChange: (value: string) => void;
  onReset?: () => void;
  isLoading?: boolean;
  onClickSubmit?: () => void;
  conversationId?: string;
  minWidth?: string;
  defaultIsOpen?: boolean;
  disableFilter?: boolean;
  className?: string;
  conversationState?: string;
  externalInputRef?: React.RefObject<HTMLInputElement>;
  inputId: string;
  canBypassDisabled?: boolean;
  setCanBypassDisabled?: (canBypassDisabled: boolean) => void;
}

export const focusBadgeColor = {
  SENTIMENT: "orange.200",
  ANALYTICAL: "twitter.50",
  NASDAQ: "purple.100",
  NYSE: "orange.100",
  TSX: "blue.100",
  MANDATORY: "orange.200",
  OPTIONAL: "twitter.50",
  RECOMMENDED: "purple.100",
};

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  initialText = "",
  suggestedQuestions = [],
  isInputDisabled = false,
  isSubmitDisabled = false,
  onSelectOption,
  value,
  onChange,
  onReset,
  isLoading = false,
  onClickSubmit,
  conversationId,
  minWidth = "32rem",
  disableFilter = false,
  defaultIsOpen = false,
  className,
  conversationState,
  externalInputRef,
  inputId,
  canBypassDisabled,
  setCanBypassDisabled,
}) => {
  const isConnected = useSelector((state: RootState) => state.websocket.isConnected);
  const inputBgColor = useColorModeValue("white", "gray.800");
  const inputBorderColor = useColorModeValue("gray.200", "gray.800");
  const submitColor = useColorModeValue("primary.default", "gray.800");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const {
    isConversationOpen,
    onConversationClose,
    onConversationOpen,
    focusedInputId,
    setFocusedInputId,
    setIsUsingDefaultConversationDialog,
    setIsAnotherInputFocused,
  } = useConversationContext();
  const { setIsNewMenuAction } = useAddToCharliContext();
  const autocompleteRef = useRef<AutoCompleteRefMethods>();
  const internalInputRef = useRef<HTMLInputElement>(null);
  const inputRef = externalInputRef || internalInputRef;
  const isMounted = useRef(false);
  const { files } = useContext(InputBarDropzoneContext);
  const { pathname } = useLocation();

  // TODO: this is a workaround to prevent the suggestion list from showing when questionText is empty
  const [showSuggestionList, setShowSuggestionList] = useState(false);

  const suggestedQuestionsWithId: (SuggestedQuestion & { id: string })[] = useMemo(() => {
    return suggestedQuestions.map((question) => ({ ...question, id: uuid() }));
  }, [suggestedQuestions]);

  const onHandleConversation = () => {
    if (isConversationOpen) {
      onConversationClose();
    } else {
      setIsNewMenuAction(conversationId ? undefined : "command");
      onConversationOpen(conversationId);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      setIsUsingDefaultConversationDialog(false);
      isConversationOpen && focusedInputId === "conversation-input" && inputRef.current.focus();
    }
  }, [focusedInputId, inputRef, isConversationOpen, setIsUsingDefaultConversationDialog]);

  const isFocusedInputDisabled = focusedInputId !== inputId;

  useEffect(() => {
    if (isConversationOpen) {
      setFocusedInputId("conversation-input");
    } else {
      setFocusedInputId("view-input");
    }
  }, [isConversationOpen, setFocusedInputId, pathname]);

  const isSubmitAvailable = useCallback((): boolean => {
    if (value.startsWith("/")) {
      return false;
    }
    return !!(
      value &&
      value.length > 0 &&
      !suggestedQuestions.some((suggestion) => suggestion.question.toLowerCase().trim() === value.toLowerCase().trim())
    );
  }, [suggestedQuestions, value]);

  const handleInputOnClick = (onOpen: () => void) => {
    if ((isInputDisabled || isFocusedInputDisabled) && !canBypassDisabled) {
      return;
    }
    setIsAnotherInputFocused(true);
    if (inputId === "view-input" && isConversationOpen) {
      onConversationClose();
    }
    setShowSuggestionList(true);
    onOpen();
  };

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      const { key } = ev;

      const suggestedQuestion =
        suggestedQuestions.length > 0 && suggestedQuestions.filter((suggestion) => suggestion.question.includes(value))[0];

      switch (key) {
        case "Enter":
          (!showSuggestionList || isSubmitAvailable()) && onClickSubmit?.();
          setShowSuggestionList(false);
          setCanBypassDisabled?.(false);
          break;

        case "Tab": {
          suggestedQuestion && onSelectOption(suggestedQuestion);
          setShowSuggestionList(false);
          setCanBypassDisabled?.(false);
          ev.preventDefault();

          break;
        }

        case " ":
          setShowSuggestionList(false);
          break;

        case "Escape":
          setShowSuggestionList(false);
          setCanBypassDisabled?.(false);
          onReset ? onReset() : onChange("");
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isSubmitAvailable,
    onClickSubmit,
    onReset,
    onChange,
    value,
    suggestedQuestions,
    onSelectOption,
    setCanBypassDisabled,
    inputRef,
    showSuggestionList,
    isConversationOpen,
    focusedInputId,
  ]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Stack spacing="0" width="100%" height="100%">
      <AutoComplete
        ref={autocompleteRef}
        onSelectOption={({ item: { originalValue } }) => {
          const suggestedQuestion = JSON.parse(originalValue) as SuggestedQuestion;
          onSelectOption(suggestedQuestion);
        }}
        defaultIsOpen={defaultIsOpen}
        disableFilter={disableFilter}
        freeSolo
        emptyState={false}>
        {({ onOpen }) => (
          <>
            <InputGroup>
              <HelpPopover
                title="Open Conversation"
                message="For advanced users, click this button to open the Charli conversation dialog.">
                <InputLeftAddon
                  backgroundColor={inputBgColor}
                  paddingInlineStart={"0"}
                  paddingInlineEnd={value.startsWith("/") ? "0" : "1rem"}
                  borderRadius="30px 0px 0px 30px!important"
                  cursor="pointer"
                  children={
                    <Stack direction={"row"} width="100%" justifyContent={"space-between"}>
                      <CharliLogoCircle className="project-conversation-button" onClick={onHandleConversation} />
                      {(value.startsWith("/") || files.length > 0 || (conversationState && conversationState === "action")) && (
                        <Center width="2rem" borderRadius={"30px 0px 0px 30px!important"}>
                          <InputBarAttachmentButton size={"md"} />
                        </Center>
                      )}
                    </Stack>
                  }
                />
              </HelpPopover>
              <AutoCompleteInput
                id={inputId}
                ref={inputRef}
                onClick={() => {
                  handleInputOnClick(onOpen);
                }}
                onBlur={() => {
                  setIsAnotherInputFocused(false);
                }}
                autoComplete={"off"}
                paddingInlineStart={"0"}
                paddingInlineEnd="2.5rem"
                borderRadius="0px 30px 30px 0px!important"
                aria-autocomplete="both"
                className={`${className ? className : "ch-question-input"}`}
                disabled={canBypassDisabled && !isFocusedInputDisabled ? false : isInputDisabled || isFocusedInputDisabled}
                backgroundColor={inputBgColor}
                placeholder={initialText}
                onChange={(ev) => {
                  setShowSuggestionList(true);
                  onChange(ev.target.value);
                }}
                _disabled={{ cursor: "not-allowed", opacity: 1, color: "gray.200" }}
                loadingIcon={<TypingIndicator size="small" />}
                size="md"
                borderColor={inputBorderColor}
                borderWidth="1px"
                borderLeftWidth="0"
                value={value}
              />
              <InputRightElement
                width="2.5rem"
                height="2.5rem"
                zIndex={value.length > 0 ? 2 : 0}
                children={
                  <IconButton
                    className="ch-autocomplete-submit"
                    aria-label="submit"
                    borderRadius={"full"}
                    color={submitColor}
                    size="sm"
                    backgroundColor={inputBgColor}
                    _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
                    _hover={{ fontWeight: "bold" }}
                    icon={<ArrowForwardIcon width="1.5rem" height="1.5rem" />}
                    isDisabled={
                      canBypassDisabled && !isFocusedInputDisabled
                        ? false
                        : isLoading || !isConnected || isSubmitDisabled || isInputDisabled || isFocusedInputDisabled
                    }
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      onClickSubmit && onClickSubmit();
                      setShowSuggestionList(false);
                    }}
                  />
                }
              />
            </InputGroup>
            {suggestedQuestionsWithId.length > 0 && showSuggestionList && (
              <AutoCompleteList
                boxShadow={"xl"}
                p="0"
                marginLeft={"-1rem!important"}
                marginTop="-2px!important"
                minWidth={["unset", "unset", minWidth]}>
                {suggestedQuestionsWithId.map(({ question, label, focus, matchFilter, id }) => (
                  <AutoCompleteItem
                    key={`option-${id}`}
                    value={JSON.stringify({ question, focus, matchFilter })}
                    className="ch-autocomplete-suggestion"
                    marginInline={0}
                    p="1rem"
                    borderRadius={"none"}>
                    <Stack direction="row" justifyContent={"space-between"} spacing="1rem" alignItems="center" width="100%">
                      <TextOverflowTooltip
                        className="ch-autocomplete-suggestion-text"
                        noOfLines={3}
                        label={label || question.replace("|", "")}
                        searchText={value}
                        highlightBackground={false}
                      />
                      {focus && (
                        <Box
                          className="ch-autocomplete-suggestion-tag"
                          borderRadius={"4px"}
                          py="2px"
                          px="5px"
                          fontSize={"10px"}
                          color="gray.600"
                          background={focusBadgeColor[focus.toUpperCase()] || "gray.100"}>
                          {isMobile ? focus.toUpperCase().slice(0, 1) : focus.toUpperCase()}
                        </Box>
                      )}
                    </Stack>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            )}
          </>
        )}
      </AutoComplete>
    </Stack>
  );
};
